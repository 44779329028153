<script>
export default {
    props: {
        lang: {},
        product: {}
    }
};
</script>

<template>
    <div>
        <lang-icon :lang="lang" />
        <template v-if="lang === 'da'"> {{ product.invoice_text }}</template>

        <template v-if="lang === 'en'"> {{ product.invoice_text_en }}</template>
        <div class="small text-muted">
            {{ product.ec_product_name }}
            ({{ product.ec_product }})
        </div>
    </div>
</template>
