<script>
import { pick } from "lodash";
import CustomerService from "@/services/CustomerService";
import CurrencyService from "@/services/CurrencyService";
import EcService from "@/services/EcService";
import SubscriptionService from "@/services/SubscriptionService";
import MGConsts from "@/mixins/MGConsts";
import SVars from "@/components/Settings/SVars";
import SettingService from "@/services/SettingService";
import ProductService from "@/services/ProductService";

export default {
    name: "VSubscriptionForm",
    components: { SVars },
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        customer_id: {
            description: "Customer ID"
        },
        customerName: {
            type: String,
            default: ""
        }
    },
    mixins: [MGConsts],
    data() {
        return {
            vars: null,
            layouts: null,
            termsOfPayment: null,
            showInvoicingOptions: false,
            editing: false,
            loading: false,
            errors: null,
            currencies: [],
            products: [],
            subscription: {
                id: null,
                customer_id: null,
                economic_product: null,
                description: null,
                start_date: null,
                stop_date: null,
                frequency: null,
                payment_strategy: null,
                amount: null,
                currency: null,
                status: null,
                product_id: null,
                invoice_text: null,
                invoice_heading: null,
                invoice_text1: null,
                invoice_text2: null,
                invoice_term_of_payment: null,
                invoice_layout: null,
                subscription_type: null,
                unit_name: null
            }
        };
    },
    computed: {
        hasInvoicingOptions: function() {
            let check = [
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout"
            ];

            let show = false;

            check.forEach(value => {
                if (this.subscription[value]) {
                    show = true;
                }
            });

            console.log("Hasinvoice", show);

            return show;
        }
    },
    async created() {
        this.loading = true;
        await this.fetchCurrencies();
        await this.fetchProducts();
        await this.fetchEconomicParams();
        await this.fetchSettings();

        if (!this.context.empty) {
            this.editing = true;
            this.subscription = pick(this.context, [
                "id",
                "customer_id",
                "description",
                "start_date",
                "stop_date",
                "frequency",
                "payment_strategy",
                "amount",
                "currency",
                "product_id",
                "status",
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
                "subscription_type",
                "unit_name"
            ]);
        } else {
            this.subscription.customer_id = this.customer_id;
        }

        await this.fixCurrency();

        this.loading = false;
    },
    methods: {
        async fetchProducts() {
            try {
                const { data } = await ProductService.GET_dropdown(
                    "subscription"
                );
                this.products = data.data;
            } catch (e) {
                console.log("pe", e);
            }
        },
        async fixCurrency() {
            try {
                const { data } = await CustomerService.GETS(
                    this.subscription.customer_id
                );
                this.$set(
                    this.subscription,
                    "currency",
                    data.data.default_currency
                );
            } catch (e) {}
        },
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await SubscriptionService.PUT(this.subscription);
                } else {
                    const r = await SubscriptionService.POST(this.subscription);
                }

                this.$emit("close-refresh");
            } catch (e) {
                console.log(e);
                this.errors = e.response.data.errors;
            }
        },
        /**
         *
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("subscription.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await SubscriptionService.DELETE(
                                this.subscription.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: e.response.data.errors.join(".\n")
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error")
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Close the subscription
         */
        async closeNow() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("subscription.close_now_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await SubscriptionService.closeNow(
                                this.subscription.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t(
                                        "subscription.error_cannot_close"
                                    ),
                                    text: e.response.data.errors.join(".\n")
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t(
                                        "subscription.error_cannot_close"
                                    ),
                                    text: this.$t("unknown_error")
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes({
                    fview: "active"
                });
                this.currencies = r.data.data;
            } catch (e) {}
        },
        /**
         * Fetch economic params
         */
        async fetchEconomicParams() {
            try {
                const r = await EcService.ecLayoutsAndTerms();
                this.layouts = r.data.data.layouts;
                this.layouts.unshift({
                    label: this.$t("use_default"),
                    value: ""
                });

                this.termsOfPayment = r.data.data.terms_of_payment;
                this.termsOfPayment.unshift({
                    label: this.$t("use_default"),
                    value: ""
                });
            } catch (e) {}
        },
        /**
         * Fetch settings data + vars
         */
        async fetchSettings() {
            try {
                const r2 = await SettingService.getNamespaceVariables(
                    "invoice_templates"
                );
                this.vars = r2.data.data;
            } catch (e) {}
        }
    }
};
</script>

<template>
    <modal
        :loading="loading"
        size="xl"
        :show="true"
        @close="$emit('close')"
        class="subscription-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("subscription.edit_subscription") }} #{{ subscription.id
                }}<template v-if="customerName"> ({{ customerName }})</template>
            </template>
            <template v-else>
                {{ $t("subscription.create_subscription") }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <FormulateForm
                v-model="subscription"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col-6">
                        <FormulateInput
                            name="subscription_type"
                            type="select"
                            :options="mgSubscriptionType"
                            :label="$t('subscription.subscription_type')"
                            :validation-name="
                                $t('subscription.subscription_type')
                            "
                            :placeholder="
                                $t('subscription.select_subscription_type')
                            "
                            validation="required"
                            :disabled="editing"
                        />
                    </div>
                    <div class="col-6">
                        <FormulateInput
                            v-if="
                                parseInt(subscription.subscription_type) === 1
                            "
                            name="amount"
                            type="currency"
                            :currency="subscription.currency"
                            number-style="money"
                            :label="$t('subscription.amount')"
                            :validation-name="$t('subscription.amount')"
                            :placeholder="$t('subscription.provide_amount')"
                            validation="required"
                        />
                        <FormulateInput
                            v-else-if="
                                parseInt(subscription.subscription_type) === 2
                            "
                            name="unit_name"
                            type="text"
                            :label="$t('subscription.unit_name')"
                        />

                        <div
                            class="text-center text-info"
                            v-else="
                                parseInt(subscription.subscription_type) === 3
                            "
                        >
                            {{ $t("subscriptions.help_text_multi_products") }}
                        </div>
                    </div>
                </div>

                <div
                    class="text-center text-info"
                    v-if="parseInt(subscription.subscription_type) === 2"
                >
                    {{ $t("subscriptions.help_text_usage_based") }}
                </div>
                <div class="row" v-if="false">
                    <div class="col-lg-6 col-sm-4 col-xs-12" v-if="false">
                        <FormulateInput
                            name="currency"
                            type="select"
                            :options="currencies"
                            :label="$t('subscription.currency')"
                            :validation-name="$t('subscription.currency')"
                            :placeholder="$t('subscription.select_currency')"
                            validation="required"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-xs-12">
                        <FormulateInput
                            name="start_date"
                            type="date"
                            :label="$t('subscription.start_date')"
                            :validation-name="$t('subscription.start_date')"
                            validation="required"
                            :disabled="editing"
                        />
                    </div>
                    <div class="col-lg-6 col-xs-12">
                        <FormulateInput
                            name="stop_date"
                            type="date"
                            :label="$t('subscription.stop_date')"
                            :help="$t('subscription.stop_date_help_text')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="frequency"
                            type="select"
                            :options="mgPaymentFrequency"
                            :label="$t('subscription.frequency')"
                            :validation-name="$t('subscription.frequency')"
                            :placeholder="$t('subscription.select_frequency')"
                            validation="required"
                            :disabled="editing"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="payment_strategy"
                            type="select"
                            :options="mgPaymentStrategy"
                            :label="$t('subscription.payment_strategy')"
                            :validation-name="
                                $t('subscription.payment_strategy')
                            "
                            :placeholder="
                                $t('subscription.select_payment_strategy')
                            "
                            validation="required"
                            :disabled="editing"
                        />
                    </div>
                </div>

                <div
                    class="row"
                    v-if="parseInt(subscription.subscription_type) !== 3"
                >
                    <div class="col">
                        <FormulateInput
                            name="product_id"
                            type="select"
                            :options="products"
                            :label="$t('subscription.product')"
                            :validation-name="$t('subscription.product')"
                            :placeholder="$t('subscription.select_product')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="description"
                            type="textarea"
                            :label="$t('subscription.optional_note')"
                        />
                    </div>
                </div>

                <!-- EXTRA INVOICING OPTIONS START -->
                <!-- Additional invoicing options -->

                <h3 class="sub-heading">
                    {{ $t("subscription.additional_invoicing_options") }}
                </h3>

                <div v-show="hasInvoicingOptions || showInvoicingOptions">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_heading"
                                type="textarea"
                                :label="
                                    $t('subscription.custom_invoice_heading')
                                "
                                :help="
                                    $t(
                                        'subscription.custom_invoice_heading_help'
                                    )
                                "
                            />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <s-vars :vars="vars.subscription_heading" />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_text1"
                                type="textarea"
                                :label="$t('subscription.custom_invoice_text1')"
                                :help="
                                    $t('subscription.custom_invoice_text1_help')
                                "
                            />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <s-vars :vars="vars.subscription_text2" />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_text2"
                                type="textarea"
                                :label="$t('subscription.custom_invoice_text2')"
                                :help="$t('subscription.custom_text2_help')"
                            />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <s-vars :vars="vars.subscription_text2" />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_layout"
                                type="select"
                                :options="layouts"
                                :label="
                                    $t('subscription.custom_invoice_layout')
                                "
                            />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_term_of_payment"
                                type="select"
                                :options="termsOfPayment"
                                :label="
                                    $t('subscription.custom_term_of_payment')
                                "
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    v-show="!hasInvoicingOptions && !showInvoicingOptions"
                >
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        {{
                            $t(
                                "subscription.additional_invoicing_options_form_text"
                            )
                        }}
                    </div>
                    <div
                        class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-right"
                    >
                        <base-button
                            size="sm"
                            @click="
                                showInvoicingOptions = !showInvoicingOptions
                            "
                        >
                            {{
                                $t(
                                    "subscription.show_additional_invoicing_options"
                                )
                            }}</base-button
                        >
                    </div>
                </div>
                <!-- EXTRA INVOICING OPTIONS END -->

                <div class="row mt-4" v-if="editing">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="alert alert-default alert-outline">
                            {{ $t("subscription.editing_note") }}
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button
                            v-if="editing"
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i>
                            {{ $t("subscription.delete") }}</base-button
                        >
                        <base-button
                            v-if="editing && subscription.status == 1"
                            type="warning"
                            :outline="true"
                            @click="closeNow()"
                            ><i class="far fa-times"></i>
                            {{ $t("subscription.stop_now") }}</base-button
                        >
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
